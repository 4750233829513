import axios, { AxiosRequestConfig, AxiosPromise } from "axios";
import { parseCookies } from "nookies";
import { getApiHost } from "common/utils";
import { message } from "antd";

// 创建取消令牌
const cancelToken = axios.CancelToken;
export const source = cancelToken.source();

const { token } = parseCookies(null, "token");
const Api = axios.create({
  // timeout: 1000 * 60
  timeout: 1000 * 90
  // withCredentials: true
});

const noWarningTipUrls = ["/api/assessment/analyze"];

Api.defaults.baseURL = ``; // 设置baseURL
Api.interceptors.request.use(
  config => {
    const { token } = parseCookies();
    if (token) {
      config.headers.Authorization = token;
    }

    return config;
  },
  error => Promise.reject(error)
);
Api.interceptors.response.use(
  response => response.data,
  error => {
    if (noWarningTipUrls.includes(error.config.url)) {
      return Promise.reject(error);
    }
    if (error.response?.data?.message) {
      message.error(`${error.response?.data?.message}`);
    } else {
      message.error(error.message);
    }

    if ([10001, 10002, 10003].includes(error.response?.data?.code)) {
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

Api.defaults.headers.common.Authorization = token;

const AdaptiveR = (urlList: any[string]) => (key: string, params?: any) => {
  const { token } = parseCookies(null, "token");
  let url = urlList[key];

  if (url.includes("{")) {
    let key = "";
    url = url.replace(/{(.*?)}/g, (_: any, val: string) => {
      key = val;
      return params[val];
    });
    delete params[key];
  }
  const [method, apiUrl] = url.split(" ");
  const requestParams: any = {
    method,
    url: `${getApiHost()}${apiUrl}`,
    cancelToken: source.token
  };
  if (method == "get") {
    requestParams.params = params;
  } else {
    requestParams.data = params;
  }

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token
    },
    ...requestParams
  };

  return Api(config);
};

const urls = {
  "common/settings": `get /api/common/settings`,
  "asset/add": `post /api/asset/add`,
  "asset/list": `get /api/asset/list`,
  "asset/copy": `post /api/asset/copy`,
  "asset/import": `post /api/asset/import`,
  "asset/select": `get /api/asset/select`,
  "asset/delete": `post /api/asset/delete`,
  "asset/update": `post /api/asset/update`,
  "asset/detail": `get /api/asset/detail?id={id}`,
  "asset/add_segment": `post /api/asset/add_segment`,
  "asset/sort_segment": `post /api/asset/sort_segment`,
  "asset/update_segment": `post /api/asset/update_segment`,
  "asset/delete_segment": `post /api/asset/delete_segment?id={id}`,
  "asset/disable": `post /api/asset/disable`,
  "asset/query_audio": `get /api/asset/query_audio?tid={tid}`,
  "asset/parse_url": `post /api/asset/parse_url`,
  "asset/chatlog_analyze": `post /api/asset/chatlog_analyze`,
  "assessment/send": `post /api/assessment/send`,
  "assessment/stop": `post /api/assessment/stop`,
  "assessment/result": `get /api/assessment/result`,
  "assessment/analyze": `get /api/assessment/analyze`,
  "assessment/list": `get /api/report/assessment/list`,
  "assessment/type_list": `get /api/report/assessment/type_list`,
  "assessment/start": `post /api/assessment/start?tutoring_id={tutoring_id}`,
  "assessment/avatar_reset": `post /api/assessment/avatar_reset`,
  "tutoring/list": `get /api/tutoring/list`,
  "tutoring/addDemand": `post /api/tutoring/addDemand`,
  "tutoring/updateDemand": `post /api/tutoring/updateDemand`,
  "course/info": `get /api/course/info`,
  "course/copy": `post /api/course/copy`,
  "course/detail": `get /api/course/detail`,
  "course/create": `post /api/course/create`,
  "course/ai_fill": `post /api/course/ai_fill`,
  "course/publish": `post /api/course/publish`,
  "course/ai_scene": `post /api/course/ai_scene`,
  "course/qas_check": `post /api/course/qas_check`,
  "course/ai_notice": `post /api/course/ai_notice`,
  "course/ai_answer": `post /api/course/ai_answer`,
  "course/update_qa": `post /api/course/update_qa`,
  "course/update_qas": `post /api/course/update_qas`,
  "course/delete_qas": `post /api/course/delete_qas?id={id}`,
  "course/templates": `get /api/course/templates?type={type}`,
  "course/ai_check_qa_sub": `post /api/course/ai_check_qa_sub`,
  "course/ai_score_points": `post /api/course/ai_score_points`,
  "course/ai_scene_setting": `post /api/course/ai_scene_setting`,
  "copy/assessment_dimension": `post /api/assessment_dimension`,
  "delete/assessment_dimension": `delete /api/assessment_dimension/{id}`,
  "copy/assessment_template": `post /api/assessment_template`,
  "delete/assessment_template": `delete /api/assessment_template/{id}`,
  "api/assessment_template": `get /api/assessment_template`,
  "api/assessment_dimension": `get /api/assessment_dimension`,
  "assessment_template/create": `post /api/assessment_template`,
  "assessment_template/update": `put /api/assessment_template/{id}`,
  "assessment_template/name_check": `get /api/assessment_template/name_check`,
  "assessment_template/with_company": `get /api/assessment_template/with_company`,
  "collection/list": `get /api/collection/list`,
  "collection/update": `post /api/collection/update`,
  "collection/list_all": `get /api/collection/list_all`,
  "collection/delete": `post /api/collection/delete?id={id}`,
  "company/update": `post /api/company/update`,
  "company/check_quota": `post /api/company/check_quota`,
  "users/info": `get /api/users/info`,
  "users/list": `get /api/users/list`,
  "dashboard/course_reports": `get /api/report/dashboard/course_reports`,
  "report/student": `get /api/report/student`,
  "report/course": `get /api/report/course`,
  "company/stats": `get /api/company/stats`,
  // "course/get_sections_setting": `get /api/course/get_sections_setting?id={id}`,
  "task/get_sections_setting": `get /api/task/get_sections_setting?id={id}`,
  "course/update_flow": `post /api/course/update_flow`,
  "course/delete_flow": `post /api/course/delete_flow`,
  "course/ai_single_flow": `post /api/course/ai_single_flow`,
  "course/add_flow": `post /api/course/add_flow`,
  "course/ai_flow_qa": `post /api/course/ai_flow_qa`,
  "course/ai_cards": `post /api/course/ai_cards`,
  "course/ai_card_tags": `post /api/course/ai_card_tags`,
  "course/ai_section_qas": `post /api/course/ai_section_qas`,
  "course/update_avatars": `post /api/course/update_avatars`,
  "course/update_audio_model": `post /api/course/update_audio_model`,
  "course/update_sections": `post /api/course/update_sections`,
  "course/ai_section_qa_options": `post /api/course/ai_section_qa_options`,
  "learn/report": `get /api/learn/report`,
  "company/ranks": `get /api/company/ranks`,
  "group/list": `get /api/group/list`,
  "group/add": `post /api/group/add`,
  "group/update": `put /api/group/update`,
  "group/delete": `delete /api/group/delete`,
  "course/check_tasks": `get /api/course/check_tasks`,
  "task/check_conditon": `post /api/task/check_conditon`,
  "task/delete": `post /api/task/delete`,
  "task/update": `post /api/task/update`,
  "task/detail": `get /api/task/detail`,
  "task/add": `post /api/task/add`,
  "task/list": `get /api/task/list`,
  "task/info": `get /api/task/info`,
  "course/tutoring_list": `get /api/course/tutoring_list`,
  "course/tutoring_detail": `get /api/course/tutoring_detail`,
  "course/sort_tutoring": `post /api/course/sort_tutoring`,
  "tutoring/ai_story": `post /api/tutoring/ai_story`,
  "learn/start": "post /api/learn/start"
};

export const R = AdaptiveR(urls);

export default Api;
